import React, { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import STRINGS, {
  PRIVACY_POLICY_LAST_MODIFIED_DATE,
} from "../../constants/PrivacyPolicy";

import PageLayout from "../../components/PageLayout";
import Header from "../../components/Header";
import PrivacyPolicySection from "../../sections/PrivacyPolicy/PrivacyPolicySection";
import ContentText from "../../components/ContentText";
import Footer from "../../components/Footer";

const PageContent = () => {
  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  }, []);

  return (
    <>
      <PrivacyPolicySection />
      <ContentText
        content={STRINGS}
        dateContent={PRIVACY_POLICY_LAST_MODIFIED_DATE}
      />
      <Footer />
    </>
  );
};

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <PageLayout title="Privacy Policy">
        <PageContent />
      </PageLayout>
    </>
  );
};

export default PrivacyPolicy;
