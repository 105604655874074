import React from "react";
import {Link} from "gatsby";
import ROUTES from "../routes";
import * as styles  from "../index.module.scss"

export const PRIVACY_POLICY_LAST_MODIFIED_DATE = "Last Modified: 2022-01-19"

const STRINGS = [
    {
        title: "Introduction",
        text: <>
            <p>
                Fearn Co LLC ("<strong>Fearn</strong>" or "<strong>We</strong>") respects your privacy and are committed
                to protecting it through our
                compliance with this policy.
            </p>
            <p>
                This policy describes the types of information we may collect from you or that you may provide when you
                visit the website fearn.co (our "<strong>Website</strong>") and our practices for collecting, using,
                maintaining,
                protecting, and disclosing that information.
            </p>
            <p>
                This policy applies to information we collect:
            </p>
            <p>
                On this Website.
            </p>
            <p>
                In email, text, and other electronic messages between you and this Website.
            </p>
            <p>
                It does not apply to information collected by:
            </p>
            <p>
                Us offline or through any other means, including on any other website operated by Fearn or any third
                party; or
            </p>
            <p>
                Any third party, including through any application or content that may link to or be accessible from the
                Website.
            </p>
            <p>
                Please read this policy carefully to understand our policies and practices regarding your information
                and how we will treat it. If you do not agree with our policies and practices, your choice is not to use
                our Website. By accessing or using this Website, you agree to this privacy policy. This policy may
                change from time to time. Your continued use of this Website after we make changes is deemed to be
                acceptance of those changes, so please check the policy periodically for updates.
            </p>
        </>
    },
    {
        title: "Children Under the Age of 18",
        text: <>
            <p>
                Our Website is not intended for children under 18 years of age. No one under age 18 may provide any
                personal information to or on the Website. We do not knowingly collect personal information from
                children under 18. If you are under 18, do not use or provide any information on this Website or through
                any of its features, register on the Website, make any purchases through the Website, or provide any
                information about yourself to us, including your name, address, telephone number, email address, or any
                screen name or user name you may use. If we learn we have collected or received personal information
                from a child under 18 without verification of parental consent, we will delete that information. If you
                believe we might have any information from or about a child under 18, please contact us at
                contact@fearn.co.
            </p>
        </>
    },
    {
        title: "Information We Collect About You and How We Collect It",
        text: <>
            <p>
                We collect several types of information from and about users of our Website, including information:
            </p>
            <p>
                By which you may be personally identified, such as name, postal address, e-mail address, telephone
                number, or any other identifier by which you may be contacted online or offline ("<strong>personal
                information</strong>");
            </p>
            <p>
                That is about you but individually does not identify you, such as IP address or location; and/or
            </p>
            <p>
                About your internet connection, the equipment you use to access our Website, and usage details.
            </p>
            <p>
                We collect this information:
            </p>
            <p>
                Directly from you when you provide it to us.
            </p>
            <p>
                Automatically as you navigate through the site. Information collected automatically may include usage
                details, IP addresses, and information collected through cookies and other tracking technologies.
            </p>
            <p>
                <em>Information You Provide to Us </em>
            </p>
            <p>
                The information we collect on or through our Website may include:
            </p>
            <p>
                Information that you provide by filling in forms on our Website. This includes information provided at
                the time of registering to use our Website or requesting further services. We may also ask you for
                information when you report a problem with our Website.
            </p>
            <p>
                Records and copies of your correspondence (including email addresses), if you contact us.
            </p>
            <p>
                Your responses to surveys that we might ask you to complete for research purposes.
            </p>
            <p>
                Details of transactions you carry out through our Website and of the fulfillment of your orders. You may
                be required to provide financial information before placing an order through our Website.
            </p>
            <p>
                Your search queries on the Website.
            </p>
            <p>
                <em>Information We Collect Through Automatic Data Collection Technologies </em>
            </p>
            <p>
                As you navigate through and interact with our Website, we may use automatic data collection technologies
                to collect certain information about your equipment, browsing actions, and patterns, including:
            </p>
            <p>
                Details of your visits to our Website, including traffic data, location data, logs, and other
                communication data and the resources that you access and use on the Website.
            </p>
            <p>
                Information about your computer and internet connection, including your IP address, operating system,
                and browser type.
            </p>
            <p>
                The information we collect automatically may include personal information or we may maintain it or
                associate it with personal information we collect in other ways or receive from third parties. It helps
                us to improve our Website and to deliver a better and more personalized service, including by enabling
                us to:
            </p>
            <p>
                Estimate our audience size and usage patterns.
            </p>
            <p>
                Store information about your preferences, allowing us to customize our Website according to your
                individual interests.
            </p>
            <p>
                Speed up your searches.
            </p>
            <p>
                Recognize you when you return to our Website.
            </p>
            <p>
                The technologies we use for this automatic data collection may include:
            </p>
            <p>
                <strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on the hard drive of your
                computer. You may refuse to accept browser cookies by activating the appropriate setting on your
                browser. However, if you select this setting you may be unable to access certain parts of our Website.
                Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue
                cookies when you direct your browser to our Website.
            </p>
            <p>
                <strong>Analytics.</strong> We use a tool called "Google Analytics" to collect information about use of
                the Website. Google Analytics collects information such as how often users visit the Website, what pages
                they visit when they do so, and what other sites they used prior to coming to the Website. We use the
                information we get from Google Analytics only to improve the Website. Google Analytics collects only the
                IP address assigned to you on the date you visit the Website, rather than your name or other identifying
                information. Although Google Analytics plants a permanent cookie on your web browser to identify you as
                a unique user the next time you visit the Website, the cookie cannot be used by anyone but Google.
                Google's ability to use and share information collected by Google Analytics about your visits to this
                site is restricted by the Google Analytics Terms of Use and the Google Privacy Policy. You can prevent
                Google Analytics from recognizing you on return visits to this site by disabling cookies on your
                browser.
            </p>
        </>
    },
    {
        title: "How We Use Your Information",
        text: <>
            <p>
                We use information that we collect about you or that you provide to us, including any personal
                information:
            </p>
            <p>
                To present our Website and its contents to you.
            </p>
            <p>
                To provide you with information, products, or services that you request from us.
            </p>
            <p>
                To fulfill any other purpose for which you provide it.
            </p>
            <p>
                To carry out our obligations and enforce our rights arising from any contracts entered into between you
                and us, including for billing and collection.
            </p>
            <p>
                To notify you about changes to our Website or any products or services we offer or provide though it.
            </p>
            <p>
                In any other way we may describe when you provide the information.
            </p>
            <p>
                For any other purpose with your consent.
            </p>

        </>
    },
    {
        title: "Disclosure of Your Information",
        text: <>
            <p>
                We may disclose aggregated information about our users, and information that does not identify any
                individual, without restriction.
            </p>
            <p>
                We may disclose personal information that we collect or you provide as described in this privacy policy:
            </p>
            <p>
                To our subsidiaries and affiliates.
            </p>
            <p>
                To contractors, service providers, and other third parties we use to support our business and who are
                bound by contractual obligations to keep personal information confidential and use it only for the
                purposes for which we disclose it to them.
            </p>
            <p>
                To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of Fearn’s assets, whether as a going concern or
                as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Fearn
                about our Website users is among the assets transferred.
            </p>
            <p>
                To fulfill the purpose for which you provide it.
            </p>
            <p>
                For any other purpose disclosed by us when you provide the information.
            </p>
            <p>
                With your consent.
            </p>
            <p>
                We may also disclose your personal information:
            </p>
            <p>
                To comply with any court order, law, or legal process, including to respond to any government or
                regulatory request.
            </p>
            <p>
                To enforce or apply our <Link href={ROUTES.termsAndConditions} className={styles.link}>terms of use</Link> and other agreements,
                including for billing and collection purposes.
            </p>
            <p>
                If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of
                Fearn, our customers, or others.
            </p>
        </>
    },
    {
        title: "Choices About How We Use and Disclose Your Information",
        text: <>
            <p>
                We strive to provide you with choices regarding the personal information you provide to us. We have
                created mechanisms to provide you with the following control over your information:
            </p>
            <p>
                <strong>Tracking Technologies and Advertising.</strong> You can set your browser to refuse all or some
                browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please
                note that some parts of this site may then be inaccessible or not function properly.
            </p>
        </>
    },
    {
        title: " Accessing and Correcting Your Information",
        text: <>
            <p>
                You can review and change your personal information by logging into the Website and visiting your
                account profile page.
            </p>
            <p>
                You may also send us an email at contact@fearn.co to request access to, correct or delete any personal
                information that you have provided to us.
            </p>
        </>
    },
    {
        title: "Your State Specific Privacy Rights",
        text: <>
            <p>
                Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter
                603A may submit a request to this designated address: contact@fearn.co. However, please know we DO NOT
                sell data triggering that statute's opt-out requirements.
            </p>
            <p>
                California's "Shine the Light" law (Civil Code Section § 1798.83) permits users of our Services that are
                California residents to request certain information regarding our disclosure of personal information to
                third parties for their direct marketing purposes. However, please know we DO NOT sell data triggering
                that statute's opt-out requirements.
            </p>
        </>
    },
    {
        title: "Data Security",
        text: <>
            <p>
                We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.
            </p>
            <p>
                The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.
            </p>
            <p>
                Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.
            </p>
        </>
    },
    {
        title: "Changes to Our Privacy Policy",
        text: <>
            <p>
                It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will notify you by email to the email address specified in your account or through a notice on the Website home page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes.
            </p>
        </>
    },
    {
        title: "Contact Information",
        text: <>
            <p>
                To ask questions or comment about this privacy policy and our privacy practices, contact us at:
            </p>
            <p>
                contact@fearn.co
            </p>
        </>
    },
];

export default STRINGS;
