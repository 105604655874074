import * as React from "react";

import * as styles from "./index.module.scss";

import SectionLayout from "../../../components/SectionLayout";
import BackgroundImage from "../../../components/BackgroundImage";
import aboutUsBanner from "../../../images/about-us.png";
import Svg from "../../../images/faq-banner.inline.svg";

const PrivacyPolicySection = () => {
  return (
    <SectionLayout className={styles.sectionWrapper}>
      <BackgroundImage
          backgroundImageDesktop={aboutUsBanner}
          backgroundImageTablet={aboutUsBanner}
          backgroundImageMobile={aboutUsBanner}
          imageStyle={styles.backgroundImage}
          Svg={Svg}
      >
        <div className={styles.backgroundImageChildren}>
          <span>Privacy policy</span>
        </div>
      </BackgroundImage>
    </SectionLayout>
  );
};

export default PrivacyPolicySection;
